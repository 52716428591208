import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { IoFastFoodOutline } from "react-icons/io5"
import { HiOutlineShoppingBag } from "react-icons/hi"
import { AiOutlinePound } from "react-icons/ai"
import { GiFilmProjector } from "react-icons/gi"

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .text-wrapper {
    max-width: 1040px;
    margin: 0 auto;
    padding: 4rem 2rem 2rem 2rem;

    .heading {
      margin-bottom: 2rem;
      width: 100%;
      z-index: 10;
      text-align: center;
      font-family: "Conv_Resolve-ExtraboldCn";
      text-transform: uppercase;
      font-size: 2.6rem;
      letter-spacing: 0.16rem;

      @media only screen and (min-width: 760px) {
        font-size: 4rem;
        letter-spacing: 0.25rem;
      }
    }

    .text-image-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      position: relative;
    }

    .gatsby-image-wrapper {
      grid-column-start: 1;
      grid-column-end: 4;
      max-width: 300px;
      margin: 0 auto;
      border-radius: 1rem;

      @media only screen and (min-width: 760px) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    .text {
      font-family: var(--font-reg);
      color: var(--black);
      z-index: 1;
      position: relative;
      /* margin-bottom: 4rem; */
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 4;

      @media only screen and (min-width: 760px) {
        grid-column-start: 2;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .mainListing {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    padding: 2rem 2rem 0 2rem;
    width: 100%;

    a.main {
      overflow: hidden;
      border-radius: 1rem 1rem 0 0;
      aspect-ratio: 16/11;

      @media only screen and (min-width: 768px) {
        aspect-ratio: 3/1;
      }

      .heading {
        width: 100%;
        z-index: 10;
        text-align: center;
        font-family: "Conv_Resolve-ExtraboldCn";
        text-transform: uppercase;

        @media only screen and (min-width: 768px) {
          font-size: 5rem;
          letter-spacing: 0.25rem;
        }
      }
    }

    .area .links {
      border-radius: 0 0 1rem 1rem;

      @media only screen and (min-width: 768px) {
        border-radius: 0;
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
    width: 100%;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .main {
      aspect-ratio: 16/11;
    }
    .area .links {
      border-radius: 0 0 1rem 1rem;
    }
  }

  .area {
    .main {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      color: white;
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;

      @media only screen and (min-width: 768px) {
        border-radius: 0 0;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }

      &.noHover {
        &:hover {
          img {
            transform: scale(1);
          }
        }
      }
    }

    .links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* grid-gap: 1rem; */
      background: var(--brandColor);
      overflow: hidden;

      .comingSoon {
        color: var(--black);
        display: flex;
        align-items: center;
        padding: 1rem;
      }

      a {
        color: var(--black);
        display: flex;
        align-items: center;
        padding: 1rem;
        transition: 0.5s all ease-in-out;

        svg {
          margin-right: 0.5rem;
        }

        &:hover {
          background: var(--black);
          color: var(--brandColor);
        }
      }
    }

    a {
      text-decoration: none;
    }

    .heading {
      width: 100%;
      z-index: 10;
      text-align: center;
      font-family: "Conv_Resolve-ExtraboldCn";
      text-transform: uppercase;
      font-size: 1.8rem;
      letter-spacing: 0.25rem;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.7;

      img {
        transition: 0.5s all ease-in-out;
      }
    }
  }
`

export default function Areas({ data }) {
  // console.log(data, process.env.GATSBY_SUBDOMAIN)

  return (
    <Wrapper>
      <div className="text-wrapper">
        <h2 className="heading">Discover Yorkshire with Us</h2>
        <div className="text-image-wrapper">
          <GatsbyImage
            image={data.map.childImageSharp.gatsbyImageData}
            alt={"Map"}
          />
          <div className="text">
            <p>
              Come with us to discover Yorkshire from the major cities of Leeds
              and York. To make it easy to navigate, we have split the county
              into two halves, exploring the west from Leeds and the east from
              York. Scroll down to the choose the area you'd like to visit, and
              use the map to explore.{" "}
            </p>
            <p>
              From exciting local independents and hidden gems to renowned
              landmarks and features on what to do, we share the best local
              experiences in each Yorkshire area. Our unique approach has been
              shaped over 10 years so that we can give people the inside
              information that inspires everyone to explore the very best of
              what this great county has to offer.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="mainListing">
        {data.mainListing.nodes.map(listing => (
          <div className="area">
            <Link to={listing.slug} className="main">
              <div className="heading">{listing.name}</div>
              <GatsbyImage
                image={
                  listing.image.image.localFile.childImageSharp.gatsbyImageData
                }
                alt={listing.name}
              />
            </Link>
            <div className="links">
              <Link to={listing.slug + "?filter=food-drink"}>
                <IoFastFoodOutline />
                <span>Food & Drink</span>
              </Link>
              <Link to={listing.slug + "?filter=shopping-lifestyle"}>
                <HiOutlineShoppingBag />
                <span>Shopping & Lifestyle</span>
              </Link>
              <Link to={listing.slug + "?filter=entertainment-culture"}>
                <GiFilmProjector />
                <span>Entertainment & Culture</span>
              </Link>
              <Link to={listing.slug + "/offers"}>
                <AiOutlinePound />
                <span>Offers</span>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="grid">
        {data.listings.nodes.map(listing => (
          <div className="area">
            <Link to={listing.slug} className="main">
              <div className="heading">{listing.name}</div>
              <GatsbyImage
                image={
                  listing.image.image.localFile.childImageSharp.gatsbyImageData
                }
                alt={listing.name}
              />
            </Link>
            <div className="links">
              <Link to={listing.slug + "?filter=food-drink"}>
                <IoFastFoodOutline />
                <span>Food & Drink</span>
              </Link>
              <Link to={listing.slug + "?filter=shopping-lifestyle"}>
                <HiOutlineShoppingBag />
                <span>Shopping & Lifestyle</span>
              </Link>
              <Link to={listing.slug + "?filter=entertainment-culture"}>
                <GiFilmProjector />
                <span>Entertainment & Culture</span>
              </Link>
              <Link to={listing.slug + "/offers"}>
                <AiOutlinePound />
                <span>Offers</span>
              </Link>
            </div>
          </div>
        ))}
        {data.comingSoon.nodes.map(listing => (
          <div className="area">
            <div className="main noHover">
              <div className="heading">{listing.name}</div>
              <GatsbyImage
                image={
                  listing.image.image.localFile.childImageSharp.gatsbyImageData
                }
                alt={listing.name}
              />
            </div>
            <div className="links">
              <div className="comingSoon">Coming soon!</div>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

export const AreasArchiveQuery = graphql`
  query Areas_Query($slug: String!) {
    map: file(name: { eq: "indy-map" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    listings: allWpListingTagLocation(
      filter: {
        acf_listing_location_fields: {
          addToAreas: { eq: true }
          areaComingSoon: { ne: true }
        }
        slug: { ne: $slug }
      }
    ) {
      nodes {
        slug
        name
        image: acf_listing_location_fields {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    comingSoon: allWpListingTagLocation(
      filter: {
        acf_listing_location_fields: { areaComingSoon: { eq: true } }
        slug: { ne: $slug }
      }
    ) {
      nodes {
        slug
        name
        image: acf_listing_location_fields {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    mainListing: allWpListingTagLocation(
      filter: {
        acf_listing_location_fields: {
          addToAreas: { eq: true }
          areaComingSoon: { ne: true }
        }
        slug: { eq: $slug }
      }
    ) {
      nodes {
        slug
        name
        image: acf_listing_location_fields {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
